<template>
  <section
    class="mx-2 my-1 px-3 py-2 rounded-lg"
    style=" box-shadow: 5px 5px 20px 5px  #31346915,-5px -5px 20px 5px #31346900;"
  >
    <p class="font-medium text-lg text-blue-600">
      {{
        $t('components.livemapManagement.details.tabs.detail.iotFaults.title')
      }}
      {{ `#${$truncate(faultData.id, -5)}` }}
    </p>
    <p class="font-medium text-xl mt-3">
      {{ faultData.fault_details }}
    </p>
    <div class="mt-3">
      <p class="text-gray-500 text-xs">
        {{
          $t(
            'components.livemapManagement.details.tabs.detail.iotFaults.status'
          )
        }}
      </p>
      <div class="w-32">
        <FaultStatusDropdown
          v-model="faultStatus"
          :options="faultStatusOptions"
          :disabled="false"
          :haveBorder="false"
          :haveSmallHeight="true"
          :haveFill="true"
          hideError
        />
      </div>
    </div>
    <div class="mt-5 flex">
      <div class="w-1/3">
        <p class="text-gray-500 text-xs">
          {{
            $t(
              'components.livemapManagement.details.tabs.detail.iotFaults.errorCode'
            )
          }}
        </p>
        <p class="text-sm font-medium">{{ faultData.fault_code }}</p>
      </div>
      <div class="w-1/3">
        <p class="text-gray-500 text-xs">
          {{
            $t(
              'components.livemapManagement.details.tabs.detail.iotFaults.uploadedAt'
            )
          }}
        </p>
        <p class="text-sm font-medium">
          {{
            getUTCAwareTime(faultData.uploaded_at, { format: 'DD MMM, YYYY' })
          }}
        </p>
      </div>
      <div class="w-1/3 flex items-end">
        <p class="text-gray-500 text-sm">{{ getTimesPassed }}</p>
      </div>
    </div>
  </section>
</template>
<script>
import FaultStatusDropdown from '@/composites/task/AddEditStatusDropdown.vue'
import { getUTCAwareTime } from '@/utils'
import { milisecToDHMSConverter } from '@/components/picker/date-range/utils/DatesUtils.js'
export default {
  name: 'IotFaultsItem',
  components: {
    FaultStatusDropdown,
  },
  props: {
    faultData: {
      type: Object,
    },
  },
  data() {
    return {
      faultStatusReq: false,
      faultStatus: null,
      faultStatusOptions: [
        {
          text: 'Unresolved',
          statusText: 'Unresolved',
          value: 'UR',
          style: {
            color: '#3B82F6',
            textColor: '#FFFFFF',
            hoverColor: '#1E3A8A',
          },
        },
        {
          text: 'Resolved',
          statusText: 'Resolved',
          value: 'R',
          style: { color: '#4BD963', textColor: '#FFFFFF' },
        },
      ],
    }
  },
  watch: {
    faultData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          console.log('faultData', data)
          this.faultStatus = data.status
        }
      },
    },
  },
  computed: {
    getTimesPassed() {
      const currentTime = new Date()

      const focusTime = new Date(this.faultData.uploaded_at)
      const diffTime = currentTime - focusTime

      return this.milisecToDHMSConverter(diffTime ? diffTime : 0)
    },
  },
  methods: {
    getUTCAwareTime,
    milisecToDHMSConverter,
  },
}
</script>
<style lang=""></style>
