export async function generateFilterOptions() {
  /* const filterableFleets = []
      await this.$http.get('/dashboard/fleets/').then(({ data }) => {
        if (data.data.length) {
          data.data.forEach((fleet) => {
            filterableFleets.push({ text: fleet.name, value: fleet.id })
          })
        }
      })
    */
  const filterOptions = [
    // {
    //   key: 'fleet',
    //   type: 'checkbox',
    //   input: filterableFleets,
    //   /*
    //     [
    //       { text: 'Fleet A', value: 'A' },
    //       { text: 'Fleet B', value: 'B' },
    //     ]
    //    */
    //   title: 'Fleet',
    // },
    {
      key: 'task_type',
      type: 'checkbox',
      input: [
        { text: 'Rebalance', value: 'REBALANCING' },
        { text: 'Maintenance', value: 'MAINTENANCE' },
        { text: 'Charging', value: 'CHARGING' },
        { text: 'Swap Battery', value: 'SWAP_BATTERY' },
      ],

      title: 'Task Type',
    },

    {
      key: 'task_status',
      type: 'checkbox',
      input: [
        { text: 'Todo', value: 'TODO' },
        { text: 'Picked', value: 'PICKED' },
        { text: 'Done', value: 'DROPPED' },
        { text: 'Cancelled', value: 'CANCELLED' },
      ],

      title: 'Status',
    },

    // {
    //   type: 'radio',
    //   key: 'status',
    //   input: [
    //     { text: 'Pending', value: 'P' },
    //     { text: 'Completed', value: 'C' },
    //   ],
    //   title: 'Status',
    // },
    // {
    //   key: 'dispute_refund', // global key of the filter (essentially the query param)
    //   type: 'checkbox-keyed', // global key won't be used, in that case set indivisual key with input
    //   input: [
    //     {
    //       key: 'is_disputed',
    //       text: 'Disputed',
    //       checkedValue: 'true', // checked value will be used in filter
    //       uncheckedValue: 'false', // default value & will be ignored
    //     },
    //     {
    //       key: 'is_refunded',
    //       text: 'Refunded',
    //       checkedValue: 'true', // checked value will be used in filter
    //       uncheckedValue: 'false', // default value & will be ignored
    //     },
    //   ],

    //   title: 'Billing',
    // },
    /* {
          // only one toggle switch per key can be added
          key: 'is_refunded',
          type: 'toggle',
          input: {
            checkedValue: 'true', // as string because it needs to be sent as string
            uncheckedValue: 'false',
            checkedLabel: 'Yes',
            uncheckedLabel: 'No',
          },
          title: 'Refunded',
        }, */
    // {
    //   key: 'amount',
    //   type: 'range',
    //   input: {
    //     value: [0, 0], // initial value of model -> [min, max]
    //     unit: '$',
    //     max: 9999,
    //     minQs: 'min_amount', // query string for min value
    //     maxQs: 'max_amount', // query string for max value
    //   },
    //   title: 'Amount',
    // },
  ]

  // console.warn({ flGen: filterableFleets }, this.filterOptions)
  return filterOptions
}

// merge summary count with component data
export function formatSummaries(summaries) {
  const generateSummaryOptions = (title, variant, icon = null) => ({
    title,
    variant,
    icon,
  })
  const generateSummaryDetails = (summaryOption, value) => ({
    ...summaryOption,
    value,
  })

  const summaryOptions = {
    total: generateSummaryOptions('Total', 'gray'),
    ongoing: generateSummaryOptions('Ongoing', 'gray'),
    rebalancing: generateSummaryOptions('Rebalancing', 'gray'),
    charging: generateSummaryOptions('Charging', 'gray'),
    done: generateSummaryOptions('Completed', 'gray'),
  }

  const formattedSummaries = []
  for (const k in summaryOptions) {
    formattedSummaries.push(
      generateSummaryDetails(summaryOptions[k], summaries[k])
    )
  }

  return formattedSummaries
}

export const taskStatusOptions = [
  {
    text: 'Todo',
    statusText: 'Todo',
    value: 'TODO',
    style: { color: '#3B82F6', textColor: '#FFFFFF', hoverColor: '#1E3A8A' },
  },
  {
    text: 'Pick',
    statusText: 'Picked',
    value: 'PICKED',
    style: { color: '#FFB740', textColor: '#FFFFFF' },
  },
  {
    text: 'Done',
    statusText: 'Done',
    value: 'DROPPED',
    style: { color: '#4BD963', textColor: '#FFFFFF' },
  },
  {
    text: 'Cancel',
    statusText: 'Cancelled',
    value: 'CANCELLED',
    style: { color: '#d90a20', textColor: '#FFFFFF' },
  },
]

export const taskTypeOptions = [
  {
    text: 'Rebalance',
    statusText: 'Rebalance',
    value: 'REBALANCING',
    style: { color: '#068EFF', textColor: '#FFFFFF' },
  },
  {
    text: 'Charging',
    statusText: 'Charging',
    value: 'CHARGING',
    style: { color: '#FF1515', textColor: '#FFFFFF' },
  },
  {
    text: 'Swap Battery',
    statusText: 'Swap Battery',
    value: 'SWAP_BATTERY',
    style: { color: '#FF8A15', textColor: '#FFFFFF' },
  },
  {
    text: 'Maintenance',
    statusText: 'Maintenance',
    value: 'MAINTENANCE',
    style: { color: '#BD00FF', textColor: '#FFFFFF' },
  },
]

export const getTaskStatusOptionsByStatus = (currentStatus, options) => {
  // currentStatus === '' || currentStatus === null || currentStatus === undefined : all
  // currentStatus === todo : pick, done, cancel
  // currentStatus === pick : done, cancel
  // else [

  if (!currentStatus) {
    return options
  } else if (currentStatus === 'PICKED') {
    const opt = [...options]
    opt.shift()
    return opt
  } else if (currentStatus === 'DROPPED') {
    const i = options.findIndex((option) => option.value === 'DROPPED')
    return [options[i]]
  } else {
    return []
  }
}

export const taskPriorityOptions = [
  {
    text: 'Low',
    statusText: 'Low',
    value: 'L',
    style: { color: '#3B82F6' },
  },
  {
    text: 'Medium',
    statusText: 'Medium',
    value: 'M',
    style: { color: '#FFB740' },
  },
  {
    text: 'High',
    statusText: 'High',
    value: 'H',
    style: { color: '#d90a20' },
  },
]
