<template>
  <div class="w-full overflow-x-auto overflow-y-hidden my-3">
    <div class="flex flex-wrap tabs-inner-wrapper mt-1 justify-between md:px-2">
      <div
        :class="{
          'tab-item px-1 md:px-2 py-3 cursor-pointer font-bold  text-sm': true,
          'text-gray-500': !item.isActive,
          'text-oBlack tab-item-active': item.isActive,
        }"
        v-for="(item, index) in items"
        :key="`tab-${index}`"
        @click="onTabClick(item)"
      >
        <!-- <span v-if="item.title === 'Hardware Actions'">
          <i class="fa fa-microchip" />
        </span>
        <span v-if="item.title === 'Tasks'">
          <i class="fa fa-tasks" />
        </span>
        <span v-if="item.title === 'Notes'">
          <i class="fa fa-comment-alt" />
        </span>
        <span v-if="item.title === 'Tags'">
          <i class="fa fa-tags" />
        </span> -->
        <span class="pl-1">{{ item.title }}</span>
        <div class="relative" v-if="item.key === 'tasks' && taskCount">
          <div
            class="absolute w-4 h-4 rounded-full  text-oWhite flex justify-center items-center"
            :class="item.isActive ? `bg-blue-500` : `bg-blue-700`"
            style="top: -31px; right: -11px"
          >
            <span class="text-xs">{{ taskCount }}</span>
          </div>
        </div>
        <div class="relative" v-if="item.key === 'iotFaults' && faultCount">
          <div
            class="absolute w-4 h-4 rounded-full  text-oWhite flex justify-center items-center"
            :class="item.isActive ? `bg-blue-500` : `bg-blue-700`"
            style="top: -31px; right: -11px"
          >
            <span class="text-xs">{{ faultCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LiveMapSmartTab',
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
    taskCount: {
      type: Number,
      required: true,
    },
    faultCount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onTabClick(item) {
      if (item.isActive) {
        return
      }

      this.$emit('tabChange', item.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-inner-wrapper {
  border-bottom: 2px solid #f6f6f6;
}

.tab-item {
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom-color: #000;
  }
}

.tab-item-active {
  border-bottom-color: #000;
}
</style>
