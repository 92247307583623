<template>
  <div>
    <!-- <TripStatusButton :busy="busy" :text="getButtonText"  /> -->
    <t-dropdown variant="smActionsLeft">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <TripStatusButton
          :busy="busy"
          :text="getButtonText"
          :aria-label="`Trip Actions`"
          :aria-haspopup="`true`"
          @blur="blurHandler"
          @focus="focusHandler"
          @keydown="keydownHandler"
          @mousedown="mousedownHandler"
        >
          <i v-if="isNotFree" class="ml-2 fas fa-chevron-down" />
        </TripStatusButton>
      </template>

      <template v-if="isNotFree" #default="{ hide }">
        <div @click="hide()" class="py-1 mt-px bg-white rounded-md shadow-lg">
          <t-dropdown-item
            class="font-medium text-14px"
            @click="action('complete')"
          >
            <!-- end is essentially complete -->
            End
          </t-dropdown-item>

          <t-dropdown-item
            class="font-medium text-14px"
            @click="action('cancel')"
          >
            Complete
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'

export default {
  name: 'VehicleMoreActions',

  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    TripStatusButton: () => import('@/composites/trip/shared/TripStatusButton'),
  },

  props: {
    vehicleId: {
      type: String,
      default: '',
    },

    tripId: {
      type: String,
      default: '',
    },

    isOnTrip: {
      type: Boolean,
      default: false,
    },

    isOnReservation: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      default: 'badge',
      validator(v) {
        return ['badge', 'emphasize'].includes(v)
      },
    },
  },

  data() {
    return {
      busy: false,
    }
  },

  computed: {
    getButtonText() {
      return this.isOnTrip
        ? 'On Rent'
        : this.isOnReservation
        ? 'On Reservation'
        : 'Free'
    },
    isNotFree() {
      return this.getButtonText !== 'Free'
    },
  },

  methods: {
    action(name) {
      const whitelist = ['cancel', 'complete']
      const isValidAction = whitelist.indexOf(name) !== -1
      if (!isValidAction || !this.tripId) {
        const text = !isValidAction
          ? 'The action is not permitted. [ERR:405]'
          : 'No trip id was provided.'

        this.$notify({
          group: 'generic',
          type: 'danger',
          title: 'Action Execution Failed!',
          text,
        })
        return
      }

      // const status = {
      //   cancel: 'A',
      //   complete: 'C',
      // }

      const titles = {
        cancel: 'Trip Ended',
        complete: 'Trip Completed',
      }
      const title = name in titles ? titles[name] : 'Action Executed'
      const titleLC = title.toLowerCase()

      const url = useEndpoints.trip.actions[name](this.tripId)
      this.busy = true
      this.$http
        .post(url)
        .then((res) => {
          console.warn(res)
          this.$notify({
            group: 'generic',
            type: 'success',
            title: title,
            text: `The ${titleLC} successfully.`,
          })

          this.$emit('change', {
            action: name,
            tripId: this.tripId,
            vehicleId: this.vehicleId,
          })
        })
        .catch((err) => {
          this.$notify({
            group: 'generic',
            type: 'danger',
            title: 'Action Execution Failed!',
            text:
              err?.response?.detail ||
              `The action encountered an error from server. [ERR:${err.response.status}]`,
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.vehicle-action-button {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  font-size: 14px;
  font-weight: 500;

  &:focus {
    outline: none;
  }

  // colors
  &.locked {
    @apply bg-oGreen;
  }
  &.unlocked {
    @apply bg-oPurple;
  }
  &.inactive {
    background-color: #c7c7c7;
  }

  // variants
  &.badge {
    width: 110px;
    height: 20px;
    border-radius: 3px;
  }

  &.emphasize {
    width: 155px;
    @apply flex items-center justify-between shadow-md rounded-full h-35px;
    @apply px-3 py-0 pl-3 text-center cursor-pointer;

    &.inactive {
      @apply bg-gray-300;
    }
  }
}
</style>
