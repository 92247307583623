<template>
  <div class="relative flex flex-col items-center justify-center">
    <div
      class="flex items-center justify-center rounded-full shadow-md w-9 h-9"
      :class="
        variant === 'dark' ? 'bg-black text-white' : 'bg-white text-black'
      "
    >
      <div
        class="circular-progress"
        :style="
          `background:conic-gradient(${getPowerFillColor} ${parseInt(
            powerLevel
          ) * 3.6}deg,#ffffff ${parseInt(powerLevel) * 3.6}deg);`
        "
      >
        <div
          class="value-container "
          :class="
            variant === 'dark' ? 'bg-black text-white' : 'bg-white text-black'
          "
        >
          <img
            :src="getVehicleTypeIcon"
            alt="vehicle-icon"
            class="w-4 h-4 -mt-1"
          />
        </div>
      </div>
    </div>
    <div
      v-if="getIcon !== null"
      class="absolute rounded-full"
      style="top: -6px; left: -5px"
    >
      <img :src="getIcon" alt="vehicle-icon" class="w-5 h-5" />
    </div>
    <div class="triangle-container">
      <div class="triangle-down" :class="variant" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VehicleMarker',

  props: {
    vehicle: {
      type: Object,
    },

    powerLevel: {
      type: [Number, String],
      required: false,
      default: 0.0,
    },

    variant: {
      type: String,
      default: 'light',
    },
  },

  computed: {
    getPowerFillColor() {
      let color = ''
      if (this.powerLevel < 25) {
        color = '#ff0000'
      } else if (this.powerLevel >= 25 && this.powerLevel < 70) {
        color = '#ffaf00'
      } else if (this.powerLevel >= 70 && this.powerLevel <= 100) {
        color = '#0ac84b'
      }
      return color
    },
    getVehicleTypeIcon() {
      let icon = ''

      if (['P', 'PP'].includes(this.vehicle.bike_category)) {
        icon = 'scooter'
      }
      if (['S'].includes(this.vehicle.bike_category)) {
        icon = 'bike'
      }
      if (['E'].includes(this.vehicle.bike_category)) {
        icon = 'ebike'
      }
      if (['C', 'K', 'M'].includes(this.vehicle.bike_category)) {
        icon = 'coco'
      }
      return require(`@/assets/img/vehicle-icons/${icon}_${this.variant}.svg`)
    },

    getIcon() {
      return this.getIconByPriority(this.vehicle, { variant: this.variant })
    },
  },

  methods: {
    getIconByPriority(vehicle, { variant = 'light' }) {
      const { is_operational } = vehicle.lock
      const { missing, geo_fence_alert } = vehicle.operational_flags
      const {
        charging_pick,
        swap_battery,
        rebalance,
        maintainance,
      } = vehicle.operational_flags
      const { is_available } = vehicle
      const {
        is_on_ride,
        is_reserved,
        is_illegally_parking,
      } = vehicle.status_flags

      const flags = {
        inactive: !is_operational,
        geo_fence_alert,
        charging_pick,
        swap_battery,
        rebalance,
        maintainance,
        unavailable: !is_available,
        is_on_ride,
        is_reserved,
        missing,
        is_illegally_parking,
        default: true,
      }

      let flag
      for (const key in flags) {
        if (flags[key]) {
          flag = key
          break
        }
      }

      // console.log(flag)

      const flagIconNames = {
        is_on_ride: {
          dark: 'on-ride.png',
          light: 'on-ride.png',
        },
        is_reserved: {
          dark: 'on-reservation.png',
          light: 'on-reservation.png',
        },
        charging_pick: {
          dark: 'on-charging-pick.png',
          light: 'on-charging-pick.png',
        },

        swap_battery: {
          dark: 'swap-battery.svg',
          light: 'swap-battery.svg',
        },
        rebalance: {
          dark: 'on-rebalance.png',
          light: 'on-rebalance.png',
        },
        maintainance: {
          dark: 'on-maintainance.png',
          light: 'on-maintainance.png',
        },
        unavailable: {
          dark: 'unavailable.svg',
          light: 'unavailable.svg',
        },
        inactive: {
          dark: 'inactive.svg',
          light: 'inactive.svg',
        },

        missing: {
          dark: 'on-missing.png',
          light: 'on-missing.png',
        },
        geo_fence_alert: {
          dark: 'on-geofence-fault.png',
          light: 'on-geofence-fault.png',
        },
        is_illegally_parking: {
          dark: 'on-illegally-parked.svg',
          light: 'on-illegally-parked.svg',
        },
      }

      const icon = flagIconNames[flag]

      if (flag !== 'default') {
        return require(`@/assets/img/flags/${icon[variant]}`)
      } else {
        return null
      }
    },

    //   Mainly created for map
    getMarkerIconSrcForVehiclePower(vehicle) {
      return {
        url: require(`@/assets/img/status/${this.getMarkerIconNameForVehiclePower(
          vehicle
        )}_icon.png`),
        scaledSize: { width: 35, height: 40 },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.circular-progress {
  @apply flex items-center justify-center rounded-full w-8 h-8;
}
.value-container {
  font-size: 5px;
  height: 75%;
  width: 75%;
  @apply flex items-center justify-center rounded-full;
}

.triangle-container {
  @apply flex justify-center items-center w-9;
  height: 5px;
  margin-left: -1px;
  margin-top: -2px;
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;

  &.dark {
    border-top: 5px solid #000 !important;
  }
  &.light {
    border-top: 5px solid #fff !important;
  }
}
</style>
