<template lang="">
  <div class="relative">
    <loading :active.sync="isLoaded" :is-full-page="false" />
    <RepairLogItem :rawData="resData" />
    <infinite-loading @infinite="fetchData" />
  </div>
</template>
<script>
import { useEndpoints } from '@/composables'
export default {
  name: 'LiveMapRepairLogs',

  components: {
    InfiniteLoading: () => import('vue-infinite-loading'),
    RepairLogItem: () => import('@/views/LiveMap/RepairLogs/RepairLogItem.vue'),
  },

  data() {
    return {
      isLoaded: false,
      resultLimit: 10,
      resData: [],
    }
  },

  computed: {
    getLimit() {
      return 10
    },
    getOffset() {
      return this.resData.length
    },
    getEndpoint() {
      return (
        useEndpoints.operationPrimer.repairAction.logs(this.$route.params.id) +
        `?limit=${this.getLimit}&offset=${this.getOffset}&status=REPAIRED`
      )
    },
  },

  methods: {
    async fetchData($state = null) {
      //   console.log('logs-fetchData', this.resData.length)
      this.isLoaded = true
      await this.$http
        .get(this.getEndpoint)
        .then((res) => {
          //   console.log('res = ', res.data)
          //   console.log('res-state= ', $state)
          if (this.resData.length) {
            this.resData = this.resData.concat(res.data?.data)
          } else {
            this.resData = res.data?.data
          }

          if ($state) {
            if (res?.data?.meta?.count?.total > this.resData.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.log('err = ', err.response.data)
        })
        .finally(() => (this.isLoaded = false))
    },
  },
}
</script>
<style lang=""></style>
