<template>
  <div class="w-56">
    <TDropdown
      ref="dropdownRef"
      :classes="{
        dropdown: 'origin-top-right mt-px right-0 w-56 bg-white shadow',
      }"
    >
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          aria-label="Vehicle Mode Dropdown"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
          class="flex justify-between items-center w-full h-14 rounded border border-gray-100 cursor-pointer select-none group app-form-reset"
        >
          <div
            class="flex gap-2 items-center px-3 w-full h-full text-sm font-medium text-gray-500 bg-gray-100 group-hover:bg-gray-50"
          >
            <span
              v-if="shapeEnabled && selected.length > 0"
              class="w-2 h-2 bg-gray-500 rounded-full"
              :style="`background-color: ${getShapeColor};`"
            />

            {{ getButtonText }}
          </div>

          <div
            class="flex justify-center items-center px-3 w-auto h-full bg-gray-50 group-hover:bg-white"
          >
            <i class="text-sm fa fa-chevron-down" />
          </div>
        </button>
      </template>

      <!-- content -->
      <div class="overflow-y-auto py-1 max-h-44 sb-farhan-light">
        <div v-for="(item, itemIndex) in opt" :key="itemIndex">
          <div
            class="flex justify-between items-center py-2 px-3 w-full h-10 text-sm text-gray-600 cursor-pointer hover:bg-gray-50"
            :class="
              `${item.askConfirmation ? 'bg-gray-100 text-gray-900' : ''}`
            "
            @click="onClickItem(item)"
          >
            <div class="flex gap-2 items-center">
              <span
                v-if="shapeEnabled"
                class="w-2 h-2 bg-gray-500 rounded-full"
                :style="`background-color: ${item.shapeColor};`"
              />

              <span>{{ item.text }}</span>
            </div>

            <span
              v-if="item.value === model"
              class="flex justify-center items-center w-4 h-4 rounded-full border"
            >
              <i
                class="tracking-tighter leading-none text-gray-400 fas fa-check"
                style="font-size: 8px;"
              >
              </i>
            </span>
          </div>

          <div
            v-if="item.askConfirmation"
            class="flex gap-2 justify-end py-1 px-2"
          >
            <AppButton
              variant="secondary"
              height="30px"
              @click="item.askConfirmation = false"
            >
              {{ $t('components.stepNavigation.cancel') }}
            </AppButton>

            <AppButton
              variant="primary"
              height="30px"
              :is-loading="busy"
              @click="onClickSave(item, itemIndex)"
            >
              {{ $t('components.stepNavigation.save') }}
            </AppButton>
          </div>
        </div>
      </div>
      <!-- /content -->
    </TDropdown>
  </div>
</template>

<script>
export default {
  name: 'AskDown',

  components: {
    AppButton: () => import('@/components/form/AppButton'),
  },

  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },

    shapeEnabled: {
      type: Boolean,
      required: false,
      deafult: false,
    },
    // I know it's an anti-pattern in vue to pass callback functions
    // I'm using this is to make the dropdown compoenent much simpler & hassel free
    // Since the use case of this component is going to have a common use case, like:
    // On clicking save, it'll send an XHR req, awaits for a 200 res & then update the model
    callback: {
      type: Function,
      // the option will be passed as the first param
      default: async () => {
        // console.log({ opt })
      },
    },

    options: {
      type: Array,
      default: () => [
        { title: 'Active', text: 'Activate', value: 'active' },
        { title: 'Inactive', text: 'Deactivate', value: 'inactive' },
        { title: 'Out of service', text: 'Out of service', value: 'unknown' },
      ],
    },
  },

  data() {
    // either map or set selected
    const mapOpt = this.options.map((x) => {
      return { ...x, selected: false, askConfirmation: false }
    })

    return {
      opt: mapOpt,

      // selected value of an option (two-way binding)
      model: null,

      //   callback function state
      busy: false,
    }
  },

  computed: {
    selected() {
      const el = this.opt.filter((el) => {
        return el.value === this.model
      })
      return el
    },

    getButtonText() {
      return this.selected[0] ? this.selected[0].title : '--'
    },

    getShapeColor() {
      const c = this.selected[0] ? this.selected[0].shapeColor : 'gray'
      // console.log({ c })
      return c
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: false,
      handler(v) {
        this.model = v
      },
    },
  },

  methods: {
    onClickItem(item) {
      this.opt.forEach((el) => {
        if (el.value === item.value) {
          el.askConfirmation = !el.askConfirmation
          return
        }

        el.askConfirmation = false
      })
    },
    async onClickSave(item, itemIndex) {
      this.busy = true
      //   console.log(this.$refs.dropdownRef.doHide())
      await this.callback(item)
        .then(() => {
          // save the current selection
          this.model = item.value
          // update v-model
          this.$emit('input', this.model)

          // hide the dropdown
          this.$refs.dropdownRef.doHide()
          // hide the confirmation buttons
          this.opt[itemIndex].askConfirmation = false
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.busy = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
