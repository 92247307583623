<template>
  <div class="wrapper" :class="getWrapperClass" @click="onToggleClick">
    <div class="w-2/6 flex justify-center">
      <slot name="icon"></slot>
    </div>
    <div class="w-4/5">
      <p class="text-xs" :class="getTitleTextClass">{{ title }}</p>
      <p class=" text-base font-semibold" :class="getStatusTextClass">
        {{ getStatusText }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SmartToggleBox',
  props: [
    'title',
    'subtitle',
    'onText',
    'onValue',
    'offText',
    'offValue',
    'isLoading',
    'loadingOnText',
    'loadingOffText',
    'value',
    'size',
    'readonly',
  ],
  data() {
    return {
      model: null,
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: false,
      handler() {
        this.model = this.value
      },
    },
    model: {
      immediate: false,
      deep: false,
      handler(updated, old) {
        if (updated === old) return
      },
    },
  },
  computed: {
    getStatusText() {
      if (this.isLoading) {
        return this.model === this.onValue
          ? this.loadingOnText
          : this.loadingOffText
      } else {
        return this.model === this.onValue ? this.onText : this.offText
      }
    },
    getWrapperClass() {
      let sizes = ['base', 'md', 'lg']
      let size = sizes.includes(this.size) ? this.size : 'base'

      let status = this.model === this.onValue ? 'wrapper-on' : 'wrapper-off'

      return 'wrapper-size-' + size + ' ' + status
    },
    getStatusTextClass() {
      return this.model === this.onValue ? 'status-on' : 'status-off'
    },
    getChecked() {
      // console.log(this.model, this.onValue);
      return this.model === this.onValue ? true : false
    },
    getTitleTextClass() {
      return this.model === this.onValue ? 'title-on' : 'title-off'
    },
  },

  methods: {
    onInput() {
      this.$emit('input', this.model)
    },
    onToggleClick() {
      this.$emit('click-box')
      if (this.readonly) return
      this.model = !this.model
      this.onInput()
      this.$emit('change', this.model)
    },
  },
}
</script>
<style lang="scss">
// class=""
.wrapper {
  @apply flex items-center rounded-md cursor-pointer;
  &.wrapper-on {
    @apply bg-blue-700;
  }
  &.wrapper-off {
    @apply bg-gray-100;
  }
  &.wrapper-size-base {
    @apply h-14;
  }

  &.wrapper-size-md {
    @apply h-16;
  }

  &.wrapper-size-lg {
    @apply h-24;
  }
  & .title-on {
    @apply text-white;
  }
  & .title-off {
    @apply text-gray-600;
  }
  & .status-on {
    @apply text-white;
  }
  & .status-off {
    @apply text-black;
  }
}
</style>
